
import Vue from "vue";
// mixins
import Api from "@/mixins/api";
// Components
import { VSelect } from "vuetify/lib";

export type TableName = {
  text: string;
  value: string;
  code: string;
};

export default Vue.extend({
  name: "VulcanizingLinkageTableSelect",
  components: {
    VSelect
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: "テーブル名"
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: true, //読み込みフラグ
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      selectItems: [] as TableName[] //テーブル名
    };
  },
  computed: {
    propsValue: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    /**
     * データベースからユーザデータ取得
     */
    async getTableName() {
      console.log("getTableName start");
      this.isLoading = true;
      const api = new Api();
      try {
        const path = api.Paths.vulcanizingLinkage.tableList;
        this.selectItems = await api.$get<TableName[]>(path);
      } catch (e) {
        this.isError = true;
        if (e instanceof Error) {
          this.errorMessage.push(e.message);
        } else {
          this.errorMessage.push("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
        console.log("getTableName end");
      }
    }
  },
  async mounted() {
    console.log("DailyWorkReportTableSelect mounted start");
    await this.getTableName();
    console.log("DailyWorkReportTableSelect mounted end");
  }
});
