
import Vue, { PropType } from "vue";

// Components
import { VIcon, VDataTable } from "vuetify/lib";
import { VulcanizingLinkageResponse } from "@/models/dailyWorkReport/response/VulcanizingLinkageResponse";

export default Vue.extend({
  name: "VulcanizingLinkageDialogTable",
  components: {
    VIcon,
    VDataTable
  },
  props: {
    items: {
      type: Array as PropType<VulcanizingLinkageResponse[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      header: [
        {
          text: "日付",
          value: "workDate"
        },
        {
          text: "日報種類",
          value: "tableName"
        },
        {
          text: "機械名",
          value: "machineName"
        },
        {
          text: "受注番号",
          value: "acceptNo"
        },
        {
          text: "品名",
          value: "itemName"
        },
        {
          text: "サイズ",
          value: "size"
        },
        {
          text: "押出数量",
          value: "extrusionQuantity"
        },
        {
          text: "数量明細",
          value: "quantityDetails"
        },
        {
          text: "連携",
          value: "link"
        }
      ]
    };
  },
  computed: {
    isLoading: {
      get(): boolean {
        return this.loading;
      },
      set(value: boolean) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    onLink(value: VulcanizingLinkageResponse) {
      this.$emit("link", value);
    }
  }
});
