
import { VulcanizingLinkageRequest } from "@/models/dailyWorkReport/request/VulcanizingLinkageRequest";
import Vue, { PropType } from "vue";
import { VCol, VRow, VBtn, VIcon, VSpacer, VForm } from "vuetify/lib";
import DatePicker from "@/components/input/picker/DatePicker.vue";
import VulcanizingLinkageTableSelect from "@/components/input/select/VulcanizingLinkageTableSelect.vue";

export default Vue.extend({
  name: "VulcanizingLinkageDialogForm",
  components: {
    DatePicker,
    VulcanizingLinkageTableSelect,
    VCol,
    VRow,
    VBtn,
    VIcon,
    VSpacer,
    VForm
  },
  props: {
    value: {
      type: Object as PropType<VulcanizingLinkageRequest>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    inputValue: {
      get(): VulcanizingLinkageRequest {
        return this.value;
      },
      set(newValue: VulcanizingLinkageRequest) {
        this.$emit("input", newValue);
      }
    }
  }
});
