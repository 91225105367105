import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const VulcanizingTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "機械名", value: "machineCode" },
  { text: "受注番号", value: "acceptNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "色", value: "color" },
  { text: "押出数量(m)", value: "extrusionQuantity" },
  { text: "数量明細(m)", value: "quantityDetails" },
  { text: "作業者", value: "userId" },
  { text: "加硫缶", value: "vulcanizingCan" },
  { text: "加硫方法", value: "vulcanizingProcess" },
  { text: "加硫窯", value: "vulcanizingKiln" },
  { text: "加硫窯サイズ", value: "vulcanizingSize" },
  { text: "気圧(Mpa)", value: "atmosphericPressure" },
  { text: "時間(分)", value: "minutes" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class VulcanizingTable {
  workDate: string;
  updateTs: string | null;
  machineCode: string | null;
  acceptNo: string | null;
  itemName: string | null;
  size: string | null;
  color: string | null;
  extrusionQuantity: string | null;
  quantityDetails: string | null;
  userId: string | null;
  vulcanizingCan: string | null;
  vulcanizingProcess: string | null;
  vulcanizingKiln: string | null;
  vulcanizingSize: string | null;
  atmosphericPressure: number | null;
  minutes: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    machineCode: string | null;
    acceptNo: string | null;
    itemName: string | null;
    size: string | null;
    color: string | null;
    extrusionQuantity: string | null;
    quantityDetails: string | null;
    userId: string | null;
    vulcanizingCan: string | null;
    vulcanizingProcess: string | null;
    vulcanizingKiln: string | null;
    vulcanizingSize: string | null;
    atmosphericPressure: number | null;
    minutes: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.machineCode = props.machineCode;
    this.acceptNo = props.acceptNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.color = props.color;
    this.extrusionQuantity = props.extrusionQuantity;
    this.quantityDetails = props.quantityDetails;
    this.userId = props.userId;
    this.vulcanizingCan = props.vulcanizingCan;
    this.vulcanizingProcess = props.vulcanizingProcess;
    this.vulcanizingKiln = props.vulcanizingKiln;
    this.vulcanizingSize = props.vulcanizingSize;
    this.atmosphericPressure = props.atmosphericPressure;
    this.minutes = props.minutes;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const VulcanizingText = {
  workDate: { text: "作業年月日", value: "workDate" },
  machineCode: { text: "機械名", value: "machineCode" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  color: { text: "色", value: "color" },
  extrusionQuantity: { text: "押出数量(m)", value: "extrusionQuantity" },
  quantityDetails: { text: "数量明細(m)", value: "quantityDetails" },
  userId: { text: "作業者", value: "userId" },
  vulcanizingCan: { text: "加硫缶", value: "vulcanizingCan" },
  vulcanizingProcess: { text: "加硫方法", value: "vulcanizingProcess" },
  vulcanizingKiln: { text: "加硫窯", value: "vulcanizingKiln" },
  vulcanizingSize: { text: "加硫窯サイズ", value: "vulcanizingSize" },
  atmosphericPressure: { text: "気圧(Mpa)", value: "atmosphericPressure" },
  minutes: { text: "時間(分)", value: "minutes" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Vulcanizing extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  acceptNo: string | null;
  itemName: string | null;
  size: string | null;
  color: string | null;
  extrusionQuantity: string | null;
  quantityDetails: string | null;
  userId: string | null;
  vulcanizingCan: string | null;
  vulcanizingProcess: string | null;
  vulcanizingKiln: string | null;
  vulcanizingSize: string | null;
  atmosphericPressure: number | null;
  minutes: number | null;
  abnormality: string | null;
  biko: string | null;
  linkWorkDate: string | null;
  linkSerialNo: number | null;
  linkNippoCode: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Vulcanizing);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Vulcanizing | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Vulcanizing) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.acceptNo = _props.acceptNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.color = _props.color;
      this.extrusionQuantity = _props.extrusionQuantity;
      this.quantityDetails = _props.quantityDetails;
      this.userId = _props.userId;
      this.vulcanizingCan = _props.vulcanizingCan;
      this.vulcanizingProcess = _props.vulcanizingProcess;
      this.vulcanizingKiln = _props.vulcanizingKiln;
      this.vulcanizingSize = _props.vulcanizingSize;
      this.atmosphericPressure = _props.atmosphericPressure;
      this.minutes = _props.minutes;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.linkWorkDate = _props.linkWorkDate;
      this.linkSerialNo = _props.linkSerialNo;
      this.linkNippoCode = _props.linkNippoCode;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "VULCAN";
    this.machineCode = null;
    this.acceptNo = null;
    this.itemName = null;
    this.size = null;
    this.color = null;
    this.extrusionQuantity = null;
    this.quantityDetails = null;
    this.userId = null;
    this.vulcanizingCan = "0";
    this.vulcanizingProcess = "0";
    this.vulcanizingKiln = "0";
    this.vulcanizingSize = null;
    this.atmosphericPressure = null;
    this.minutes = null;
    this.abnormality = null;
    this.biko = null;
    this.linkWorkDate = null;
    this.linkSerialNo = null;
    this.linkNippoCode = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
