
import moment from "moment";
import Vue from "vue";

//class
import { DATE_FORMAT } from "@/defines";

// Components
import { VulcanizingLinkageRequest } from "@/models/dailyWorkReport/request/VulcanizingLinkageRequest";
import { VulcanizingLinkageResponse } from "@/models/dailyWorkReport/response/VulcanizingLinkageResponse";

// mixins
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import VulcanizingLinkageDialogForm from "@/components/dialog/linkage/VulcanizingLinkageDialogForm.vue";
import VulcanizingLinkageDialogTable from "@/components/dialog/linkage/VulcanizingLinkageDialogTable.vue";

const DATE_FROM = moment()
  .subtract(1, "week")
  .format(DATE_FORMAT.DATE);
const DATE_TO = moment().format(DATE_FORMAT.DATE);

export default Vue.extend({
  name: "VulcanizingLinkageDialog",
  components: {
    VulcanizingLinkageDialogForm,
    VulcanizingLinkageDialogTable
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: "加硫連動",
      records: [] as VulcanizingLinkageResponse[],
      form: new VulcanizingLinkageRequest({
        workDateFrom: DATE_FROM,
        workDateTo: DATE_TO,
        tableName: null
      }),
      isLoading: false
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  },
  watch: {
    async value(newValue: boolean) {
      if (newValue) {
        await this.onClick();
      }
    }
  },
  methods: {
    async onClick() {
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const result = await api.$post<VulcanizingLinkageResponse[]>(
          api.Paths.vulcanizingLinkage.search,
          this.form
        );
        if (result) {
          this.records = result;
        }
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
      }
    },
    onLink(value: VulcanizingLinkageResponse) {
      this.$emit("link", value);
      this.inputValue = false;
    }
  },
  async mounted() {
    await this.onClick();
  }
});
