export class VulcanizingLinkageRequest {
  workDateFrom: string;
  workDateTo: string;
  tableName: string | null;

  constructor(props: {
    workDateFrom: string;
    workDateTo: string;
    tableName: string | null;
  }) {
    this.workDateFrom = props.workDateFrom;
    this.workDateTo = props.workDateTo;
    this.tableName = props.tableName;
  }
}
